.wrapper {
    margin-bottom: 40px;
    min-width: 320px;
}

.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 600px;
}

.header {
    background: hsl(0, 0%, 100%);
}

.header .container {
    padding: 20px;
}

.logo {
    max-width: 115px;
}

.title {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
}

.subtitle {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
}

.info {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 4px;
    color: #999999;
}

.wrapper .headerDivider {
    margin-top: 0;
}
