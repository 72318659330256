.container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    flex-direction: column;
}

.title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 800;
    font-size: 16px;
}
