.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.button .icon {
    color: #cccccc;
    font-size: 22px;
}
