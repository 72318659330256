.errorBg {
    background: #f5f5f5;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30vh;
}

.title {
    font-size: 20px;
    font-weight: 800;
}

.subtitle {
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
}
